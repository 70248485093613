<template>
  <div class="agency">
    새로 변경된 페이지로 이동합니다.
  </div>
</template>

<script>
export default {
  name: 'Agency',
  mounted() {
    window.location.href = 'https://www.finephone.com/agency'
  },
  computed: {}
}
</script>
